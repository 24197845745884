import React from "react"
import { Link } from "gatsby"

import { rhythm } from "../utils/typography"
import "../tags.css"

function BlogPost(props) {
  const tagLinks = props.frontmatter.tags.map((tag) => {
    return (
      <li key={tag} className={`tags-item`}>
        <Link to={`/blog/tags/${tag}`}>
          {tag}
        </Link>
      </li>
    )
  })
  return (
    <div>
      <div style={{
        marginTop: rhythm(1.0),
        marginBottom: rhythm(1.0)
      }}>
        <h2 style={{
          marginTop: rhythm(0),
          marginBottom: rhythm(0),
        }}>
          {props.frontmatter.title}
        </h2>
        <small
          style={{
            display: `block`,
          }}
        >
          {props.frontmatter.date}
        </small>
        <small
          style={{
            display: `block`,
            marginBottom: rhythm(0.5),
          }}
        >
          Tags:{` `}
          <ul className={`tags-list`}>
            {tagLinks}
          </ul>
        </small>
      </div>
      <div dangerouslySetInnerHTML={{ __html: props.html }} />
    </div>
  )
}

export default BlogPost
